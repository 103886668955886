exports.components = {
  "component---src-components-articles-article-details-js": () => import("./../../../src/components/articles/articleDetails.js" /* webpackChunkName: "component---src-components-articles-article-details-js" */),
  "component---src-components-performances-performance-details-js": () => import("./../../../src/components/performances/performanceDetails.js" /* webpackChunkName: "component---src-components-performances-performance-details-js" */),
  "component---src-components-profiles-profile-details-js": () => import("./../../../src/components/profiles/profileDetails.js" /* webpackChunkName: "component---src-components-profiles-profile-details-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-bibliographies-js": () => import("./../../../src/pages/bibliographies.js" /* webpackChunkName: "component---src-pages-bibliographies-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-meet-the-team-js": () => import("./../../../src/pages/meet-the-team.js" /* webpackChunkName: "component---src-pages-meet-the-team-js" */),
  "component---src-pages-performances-js": () => import("./../../../src/pages/performances.js" /* webpackChunkName: "component---src-pages-performances-js" */),
  "component---src-pages-settings-js": () => import("./../../../src/pages/settings.js" /* webpackChunkName: "component---src-pages-settings-js" */)
}

